import * as React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const FundInfoPage = (props) => {
  const name = props.pageContext.name;
  const data = props.data.allFundinfoJson.group;
  // Remove fund of funds entries with another parent amc
  const filtered_fund_list = data.filter((x) => {
    return (
      x.nodes[0]._mydata.find((y) => y.name === x.fieldValue).amc_code === name
    );
  });
  const fund_list = filtered_fund_list;
  return (
    <Layout>
      <Seo
        title={`รายชื่อกองทุนที่มีกองทุนหลักของ ${name}`}
        description={`ข้อมูลกองทุน ที่ลงทุนในกองทุนต่างประเทศ ที่บริหารโดย บลจ. ${name}`}
      />
      <div className="container mx-auto px-4 max-w-screen-md">
        <section className="mt-1">
          <div className="px-1 pt-4">
            <h1 className="text-2xl text-gray-700">
              รายชื่อกองทุนจาก <br className="sm:hidden" />
              {name}
            </h1>

            <div
              className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-600 p-4 mt-2 mb-5"
              role="alert"
            >
              <p className="font-bold">โปรดทราบ: หน้านี้อยู่ระหว่างทดสอบ</p>
              <p>
                ปัจจุบันยังอยู่ระหว่างการรวบรวมข้อมูลกองทุน
                ข้อมูลจึงยังไม่ครบถ้วน
              </p>
            </div>

            <div
              className="bg-white border border-gray-300 text-gray-600 rounded-md my-4"
              key={1}
            >
              <div className="divide-y divide-gray-300">
                {fund_list.map((x) => {
                  return (
                    <div key={x.fieldValue} className="px-4 py-2 text-gray-400">
                      <p className="mb-2 text-lg text-gray-600">
                        {x.fieldValue}
                      </p>
                      {x.nodes
                        .filter((item, index, arr) => {
                          // Remove duplicate items due to graphql grouping
                          return (
                            arr.findIndex(
                              (thisitem) => thisitem.proj_id === item.proj_id
                            ) === index
                          );
                        })
                        .map((y) => {
                          if (y._class) {
                            return (
                              <p
                                key={y.proj_id}
                                className="ml-1 mb-3 overflow-visible"
                              >
                                <span
                                  className="mr-2 bg-gray-100 border border-gray-300 text-sm text-gray-500 rounded-md px-2 py-1"
                                  key={y._class[0].class_abbr_name}
                                >
                                  <Link to={`/fund/${y._class[0].class_abbr_name}`}>
                                    {/* {y._class[0].class_abbr_name} */}
                                    {y.proj_abbr_name}
                                  </Link>
                                </span>
                                <span
                                  key={`${y.proj_abbr_name}__other`}
                                >
                                  (มีหลายประเภท)
                                </span>
                                {/* {y._class.map((c) => {
                                  return (
                                    <span
                                      className="mr-2 bg-gray-100 border border-gray-300 text-sm text-gray-500 rounded-md px-2 py-1"
                                      key={c.class_abbr_name}
                                    >
                                      <Link to={`/fund/${c.class_abbr_name}`}>
                                        {c.class_abbr_name}
                                      </Link>
                                    </span>
                                  );
                                })} */}
                              </p>
                            );
                          } else {
                            return (
                              <p
                                key={y.proj_id}
                                className="ml-1 mb-3 overflow-visible"
                              >
                                <span className="mr-2 bg-gray-100 border border-gray-300 text-sm text-gray-500 rounded-md px-2 py-1">
                                  <Link to={`/fund/${y.proj_abbr_name}`}>
                                    {y.proj_abbr_name}
                                  </Link>
                                </span>
                              </p>
                            );
                          }
                        })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default FundInfoPage;

export const query = graphql`
  query($name: String) {
    allFundinfoJson(
      filter: { _mydata: { elemMatch: { amc_code: { eq: $name } } } }
    ) {
      group(field: _mydata___name) {
        fieldValue
        nodes {
          proj_id
          proj_abbr_name
          proj_name_en
          proj_name_th
          _class {
            class_abbr_name
          }
          _mydata {
            amc_code
            link
            name
            class
            currency
          }
        }
      }
    }
  }
`;
